import * as React from 'react';

type ContractsProps = {
  contracts: string[];
  withMicroData?: boolean;
};

const Contracts: React.VoidFunctionComponent<ContractsProps> = ({
  contracts,
  withMicroData = false,
}) => {
  return (
    <ul className="inline-block list-none">
      {contracts.map((contract, idx) => (
        <li className="inline-block" key={contract}>
          <span {...(withMicroData ? { itemProp: 'employmentType' } : {})}>{contract}</span>
          {idx < contracts.length - 1 ? ', ' : ''}
        </li>
      ))}
    </ul>
  );
};

export default Contracts;
