import React from 'react';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { LocalizedLink } from 'gatsby-theme-i18n';

type SharedProps = {
  title: string;
  linkTo: string;
  externalUrl?: string;
  language?: string;
  withMicroData?: boolean;
};

const SharedCard: React.VoidFunctionComponent<
  SharedProps & { className?: string; children: React.ReactNode }
> = ({ title, linkTo, externalUrl, language, className = '', withMicroData = false, children }) => {
  if (externalUrl) {
    return (
      <a
        href={externalUrl}
        title={title}
        className={`${className} block`}
        target="_blank"
        rel="noopener noreferrer"
        itemProp="url"
      >
        {children}
      </a>
    );
  }
  return (
    <LocalizedLink
      to={linkTo}
      language={language}
      title={title}
      className={`${className} block`}
      {...(withMicroData ? { itemProp: 'url' } : {})}
    >
      {children}
    </LocalizedLink>
  );
};

type CardProps = SharedProps & {
  themeClassName: string;
  children: React.ReactNode;
};

const Card: React.VoidFunctionComponent<CardProps> = ({
  title,
  linkTo,
  language,
  themeClassName,
  withMicroData,
  children,
}) => {
  return (
    <SharedCard
      title={title}
      linkTo={linkTo}
      language={language}
      className={`${themeClassName} mb-4 block w-full translate-x-0 transform px-4 pb-0 pt-4 no-underline transition-transform duration-700 hover:translate-x-2 sm:px-8 sm:pb-5 sm:pt-8 md:px-10 md:pb-9`}
      withMicroData={withMicroData}
    >
      {children}
    </SharedCard>
  );
};

export default Card;

export type InformationType = {
  title: string;
  description: string | React.ReactElement;
};
type NewsCardProps = SharedProps & {
  description: string;
  tags: string[];
  informations: InformationType[];
  image?: IGatsbyImageData;
};

const NewsCard: React.VoidFunctionComponent<NewsCardProps> = ({
  title,
  linkTo,
  language,
  withMicroData,
  description,
  tags,
  informations,
  image,
  externalUrl,
}) => {
  return (
    <SharedCard
      title={title}
      linkTo={linkTo}
      externalUrl={externalUrl}
      language={language}
      withMicroData={withMicroData}
      className="mb-5 lg:mr-5"
    >
      <div className="flex max-w-sm flex-col justify-between overflow-hidden rounded p-4 shadow-2xl sm:w-96 lg:min-h-[42rem]">
        <div className="flex flex-col overflow-hidden">
          {image && <GatsbyImage image={image} title={title} alt={title} />}
          <div className="py-5">
            {tags.map((tag) => (
              <span
                key={tag}
                className="mb-2 mr-2 inline-block bg-reacteev-blue px-3 py-1 font-medium text-white"
              >
                {tag}
              </span>
            ))}
            <header>
              <h2
                className="mb-2 mt-1 text-left text-base font-semibold text-black lg:min-h-[68px] lg:text-lg"
                itemProp="name headline"
              >
                {title}
              </h2>
            </header>
            <section>
              <p
                className="line-clamp-3 text-xs font-medium text-gray-500 lg:min-h-[75px] lg:text-sm"
                itemProp="abstract"
              >
                {description}
              </p>
            </section>
          </div>
        </div>
        <div className="flex flex-wrap justify-between whitespace-nowrap sm:flex-nowrap sm:justify-start">
          {informations.map((information) => (
            <div key={information.title} className="mr-0 sm:mr-8">
              <div className="text-xs font-semibold text-black">{information.title}</div>
              <div className="text-xs font-medium text-gray-500">{information.description}</div>
            </div>
          ))}
        </div>
      </div>
    </SharedCard>
  );
};

export { NewsCard, SharedCard };
