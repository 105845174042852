import * as React from 'react';

type MeetButtonProps = {
  href: string;
  text: string;
  pseudoClass: string;
};

const MeetButton: React.VoidFunctionComponent<MeetButtonProps> = ({ href, text, pseudoClass }) => {
  return (
    <a
      href={href}
      className={`button my-5 inline-block border border-solid border-current px-6 py-3 text-base font-medium ${pseudoClass}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      {text}
    </a>
  );
};

export default MeetButton;
