import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { GatsbyImageFileWithName } from '../../utils/graphql';

type BannerProps = {
  clientImages: GatsbyImageFileWithName[];
  imageClassName?: string;
};

const Benner: React.VoidFunctionComponent<BannerProps> = ({
  clientImages,
  imageClassName = 'm-2 h-16 w-16 md:mx-8 md:h-44 md:w-44',
}) => {
  const content = [
    { name: 'Accor', logo: 'accor' },
    { name: 'Airbus', logo: 'airbus' },
    { name: 'BNP Paribas', logo: 'bnp' },
    { name: 'Groupe Renault', logo: 'groupeRenault' },
    { name: 'ING', logo: 'ing' },
    { name: 'Ingenico Group', logo: 'ingenicoGroup' },
    { name: 'Lacoste', logo: 'lacoste' },
    { name: 'Natixis', logo: 'natixis' },
    { name: 'Sage', logo: 'sage' },
    { name: 'Société Générale', logo: 'societeGenerale' },
    { name: 'Thalès', logo: 'thales' },
  ].map((client) => ({
    ...client,
    image: clientImages.find((image) => image.name === client.logo),
  }));

  const clientImagesDisplay = function () {
    return content.map((client) =>
      client.image ? (
        <GatsbyImage
          key={client.logo}
          image={client.image.childImageSharp.gatsbyImageData}
          title={client.name}
          alt={client.name}
          objectFit="contain"
          class={imageClassName}
        />
      ) : null,
    );
  };

  return (
    <div className="w-max animate-defilement-rtl">
      {clientImagesDisplay()}
      {clientImagesDisplay()}
    </div>
  );
};

export default Benner;
