import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyVideoFileWithPathAndExt } from '../../utils/graphql';
import VideoPlayer from '../VideoPlayer';

type PresentationVideoProps = {
  notFullScreen?: string;
};

const PresentationVideo: React.VoidFunctionComponent<PresentationVideoProps> = ({
  notFullScreen = 'h-screen',
}) => {
  const { file }: { file: GatsbyVideoFileWithPathAndExt } = useStaticQuery(
    graphql`
      {
        file(sourceInstanceName: { eq: "videos" }, name: { eq: "Reacteev" }) {
          name
          relativePath
          ext
          videoH264(maxWidth: 1920) {
            path
          }
          videoH265(maxWidth: 1920) {
            path
          }
          videoVP9(maxWidth: 1920) {
            path
          }
          videoScreenshots(width: 1920, timestamps: "1") {
            publicURL
          }
        }
      }
    `,
  );

  return (
    <section id="presentation">
      <div
        className={`flex ${notFullScreen} w-full snap-start items-center justify-center overflow-hidden bg-black text-white`}
      >
        <VideoPlayer videoFile={file} />
      </div>
    </section>
  );
};

export default PresentationVideo;
