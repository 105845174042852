import * as React from 'react';

type CitiesProps = {
  cities: string[];
  withMicroData?: boolean;
};

const Cities: React.VoidFunctionComponent<CitiesProps> = ({ cities, withMicroData = false }) => {
  return (
    <ul className="inline-block list-none">
      {cities.map((city, idx) => (
        <li
          className="inline-block"
          key={city}
          {...(withMicroData
            ? { itemScope: true, itemProp: 'jobLocation', itemType: 'https://schema.org/Place' }
            : {})}
        >
          <span {...(withMicroData ? { itemProp: 'address' } : {})}>{city}</span>
          {idx < cities.length - 1 ? ', ' : ''}
        </li>
      ))}
    </ul>
  );
};

export default Cities;
