import { Node, PageProps } from 'gatsby';
import { IGatsbyImageData } from 'gatsby-plugin-image';

export type SiteMetadataSocialProps = {
  name: string;
  url: string;
};

export type SiteMetadataProps = {
  title: string;
  description: string;
  author: string;
  siteUrl: string;
  social: SiteMetadataSocialProps[];
};

export type SiteQueryProps = {
  siteMetadata: SiteMetadataProps;
};

export type PageContextProps = {
  locale: string;
  dateFormat: string;
  originalPath?: string;
  hrefLang?: string;
  frontmatter?: Record<string, string>;
};

export type VoidPageProps = PageProps<void, PageContextProps>;

export type GatsbyImageData = {
  gatsbyImageData: IGatsbyImageData;
};

export type GatsbyImageFile = Node & {
  childImageSharp: Node & GatsbyImageData;
};

export type GatsbyImageFileWithName = GatsbyImageFile & { name: string };

export type GatsbyVideoFile = Node & {
  videoH264: { path: string };
  videoH265: { path: string };
  videoVP9: { path: string };
  videoScreenshots: [{ publicURL: string }];
};

export type GatsbyVideoFileWithPathAndExt = GatsbyVideoFile & {
  name: string;
  relativePath: string;
  ext: string;
};

export type Author = {
  firstName: string;
  lastName: string;
  fullName: string;
  normalizedFullName: string;
  email: string;
  bio?: string;
  avatar?: GatsbyImageFile;
};

export type PostMdxFrontmatter = {
  title: string;
  authors?: Author[];
  date?: Date;
  abstract?: string;
  duration?: number;
  type: string;
  slug?: string;
  externalUrl?: string;
  tags: string[];
  theme: string;
  image?: GatsbyImageFile;
  imageAlt?: string;
  socialImage?: GatsbyImageFile;
  showTableOfContents?: boolean;
};

export type JobMdxFrontmatter = {
  title: string;
  domains: string[];
  cities: string[];
  contracts: string[];
};

export type MdxFields = {
  slug: string;
  date: Date;
  locale: string;
  image?: GatsbyImageFile;
  socialImage?: GatsbyImageFile;
};

type TOCItem = {
  title: string;
  url: string;
  items?: TOCItem[];
};

export type TOC = {
  items?: TOCItem[];
};

export type GraphqlPost = {
  frontmatter: PostMdxFrontmatter;
  fields: MdxFields;
  excerpt: string;
  tableOfContents: TOC;
  timeToRead: number;
  body: string;
};

export type GraphqlJob = {
  frontmatter: JobMdxFrontmatter;
  fields: MdxFields;
  body: string;
};

export type AllMdxPost = {
  nodes: GraphqlPost[];
};

export type AllMdxJob = {
  nodes: GraphqlJob[];
};

export type AllImage = {
  nodes: GatsbyImageFileWithName[];
};

export type AllVideo = {
  nodes: GatsbyVideoFileWithPathAndExt[];
};

export type AllAuthor = {
  nodes: Author[];
};

export type Person = {
  id: string;
  fullName: string;
  job?: string;
  image?: GatsbyImageFileWithName;
  description?: string;
};

export type TrainingProgram = {
  day1: string[];
  day2?: string[];
};

export type TrainingPracticals = {
  session: string;
  price: string;
  prerequisites: string;
};

export type TrainingDetails = {
  objectives: string[];
  time: string[];
  concernedPeople: string[];
  program: TrainingProgram;
  practicals?: TrainingPracticals;
};

export type TrainingInfo = {
  id: string;
  title: string;
  image: string;
  content: string;
  details: TrainingDetails;
};

export type Post = {
  title: string;
  abstract: string;
  type: string;
  tags: string[];
  theme: string;
  slug: string;
  externalUrl?: string;
  date: Date;
  locale: string;
  lang?: string;
  authors: Author[];
  tableOfContents: TOC;
  timeToRead: number;
  duration?: number;
  image?: GatsbyImageFile;
  imageAlt?: string;
  socialImage?: GatsbyImageFile;
  body: string;
};

export type Job = {
  title: string;
  domains: string[];
  cities: string[];
  contracts: string[];
  slug: string;
  locale: string;
  date: Date;
  body: string;
};

export function graphql2post(node: GraphqlPost): Post {
  return {
    title: node.frontmatter.title,
    abstract: node.frontmatter.abstract ?? node.excerpt,
    type: node.frontmatter.type,
    tags: node.frontmatter.tags,
    theme: node.frontmatter.theme,
    slug: node.fields.slug,
    externalUrl: node.frontmatter.externalUrl,
    date: node.fields.date,
    locale: node.fields.locale,
    authors: node.frontmatter.authors as Author[],
    tableOfContents: node.tableOfContents,
    timeToRead: node.timeToRead,
    duration: node.frontmatter.duration,
    image: node.fields.image ?? node.frontmatter.image,
    imageAlt: node.frontmatter.imageAlt,
    socialImage: node.fields.socialImage ?? node.frontmatter.socialImage,
    body: node.body,
  };
}

export function graphql2job(node: GraphqlJob): Job {
  return {
    title: node.frontmatter.title,
    domains: node.frontmatter.domains,
    cities: node.frontmatter.cities,
    contracts: node.frontmatter.contracts,
    slug: node.fields.slug,
    locale: node.fields.locale,
    date: node.fields.date,
    body: node.body,
  };
}
